<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">

			<number-bars class="number-bars--orange number-bars--align-left" :title="`Value of \nFund/SERV Transactions`" unit="( in trillions )" :numbers="valueFundServ" />

			<number-bars class="number-bars--orange number-bars--align-left" :title="`Volume of \nFund/SERV Transactions`"
			 unit="( in millions )" :numbers="volumeFundServ" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'AlternativeInvestment',
	components: {
		NumberBars,
	},
	data: () => ({
		valueFundServ: [
			{
				value: 8.6,
				label: '2020',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				value: 6.60,
				label: '2019',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				value: 6.70,
				label: '2018',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
		],
		volumeFundServ: [
			{
				value: 263,
				label: '2020',
			},
			{
				value: 240,
				label: '2019',
			},
			{
				value: 238,
				label: '2018',
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
